import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useIntlAttribute } from '../hooks/useIntlAttribute';
import styles from './AppFooter.module.scss';

const AppFooter = ({ fullPage }: { fullPage?: boolean }) => {
  const copyrightYear = new Date().getUTCFullYear().toString();
  return (
    <footer className={classNames(styles.mainFooter, { [styles.fullPage]: fullPage })} role='contentinfo'>
      <div className={classNames('ef-container', styles.mainFooterContainer)}>
        <span className={classNames(styles.mainFooterFooterLeft)}>
          <FormattedMessage
            id='AppFooter.copyrightText'
            defaultMessage='© Signum International AG {copyrightYear}. All rights reserved.'
            values={{ copyrightYear, br: <br /> }}
          />
        </span>
        <span className={classNames(styles.mainFooterFooterRight)}>
          <a
            href={useIntlAttribute({
              id: 'AppFooter.legalNoticeUrl',
              defaultMessage: 'https://www.eftours.com/legal/legal-notices',
            })}
            target='_blank'
          >
            <FormattedMessage id='AppFooter.legalNoticeText' defaultMessage='Privacy Policy and Legal Notices' />
          </a>
        </span>
      </div>
    </footer>
  );
};

export { AppFooter };
